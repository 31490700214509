<template>
    <div class="popup-layout">
        <div class="popup popup--6">
            <div class="helperPopupContent">
                <button type="button" class="popup__close" @click="onClose"></button>
                <p class="h2 helperPopupContent__title">
                    Оставить заявку
                </p>
                <div>
                    <p class="colorMirage">
                        Если Вы не уверены какая программа Вам подходит - мы можем
                        подобрать программу для Вас. Для этого нужно чтобы наш координатор
                        связался с Вами.
                    </p>

                    <div class="helperPopupContent__buttons">
                        <Button
                            title="отмена"
                            extraClass="button--white"
                            :onClick="() => onClose()"
                        />
                        <Button
                            title="Подобрать"
                            extraClass="button"
                            :onClick="() => onConfirm()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Input from "@/views/components/Input/Input";
export default {
    name: "helperPopupContent",

    components: {
        Button,
        Input
    },

    props: {
        onClose: Function,
        onConfirm: Function
    },

    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "helperPopupContent.scss";
</style>
