<template>
    <div class="popup-layout">
        <div class="popup popup--8">
            <div class="confirmation">
                <p v-if="title" class="h3 confirmation__title">
                    {{ title }} за
                    <span
                        v-if="points"
                        class="h3 boxText2 boxText2--lightOrange confirmationPoints"
                    >
                        {{ points }}
                    </span>
                    баллов!
                </p>
                <div>
                    <div class="confirmation__buttons">
                        <Button
                            title="отмена"
                            extraClass="button--white"
                            :onClick="onClose"
                        />
                        <Button
                            :title="btnText"
                            extraClass="button--confirm"
                            :onClick="onConfirm"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";

export default {
    name: "Confirmation",

    components: {
        Button,
    },

    props: {
        title: String,
        points: String,
        onClose: Function,
        onConfirm: Function,
        btnText: String,
    },

    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
@import "Confirmation.scss";
</style>
