<template>
    <Layout>
        <div class="dashboardShopHeader">
            <PageHeader
                :items="items"
                :title="title"
                :link="`/${this.$route.params.slug}/dashboard`"
            />
            <div class="dashboardShopHeader__content">
                <b-skeleton
                    v-if="isLoadingActivePointsData"
                    type="button"
                    width="150px"
                ></b-skeleton>
                <p class="h2" v-else>
                    <span class="dashboardShopHeader__text">
                        Доступно баллов
                    </span>
                    <span class="boxText2 boxText2--lightOrange">{{ myPointsCoin }}</span>
                </p>
                <div
                    v-if="daysTillPointsReset"
                    class="courseEndedText dashboardShopHeader__daysTillPointsReset"
                >
                    <p class="h4 colorGray">До сгорания баллов:</p>
                    <p class="courseEndedTime title1 title1--2">
                        <b>{{ daysTillPointsReset }}</b>
                        <span v-if="daysTillPointsReset === 1"> день</span>
                        <span
                            v-else-if="daysTillPointsReset > 1 && daysTillPointsReset < 5"
                        >
                            дня</span
                        >
                        <span v-else> дней</span>
                    </p>
                    &nbsp;&nbsp;
                    <Tooltip
                        :tooltipIcon="require('@/assets/img/common/info.svg')"
                        text="У начисленных Вам баллов ограниченный срок использования, слева написано через сколько дней баллы сгорят, поэтому рекомендуем использовать их до этого момента"
                    />
                </div>
                <b-skeleton
                    v-if="isLoadingActivePointsData"
                    style="margin-top: 8px"
                ></b-skeleton>
                <p class="dashboardShopHeader__button" v-else>
                    <button
                        class="btn-text colorYellow "
                        @click="onOpenModalEarningPoints()"
                    >
                        как заработать баллы
                    </button>
                </p>
            </div>
        </div>
        <div class="pageSection">
            <div class="pageContent pageContent--2 ">
                <p class="h2 colorFiord">
                    Повысьте эффективность своего обучения
                </p>
                <div
                    class="dashboardShopContent"
                    v-if="
                        this.$store.getters.getDashboardShop.shop.studyOptions.filter(
                            (item) => item.status === 'active'
                        ).length > 0
                    "
                >
                    <div
                        :class="
                            viewAll
                                ? `dashboardShopContent__allItem dashboardShopContent__allItem--hidden`
                                : `dashboardShopContent__allItem`
                        "
                        v-for="(item, index) in efficiencyItemsData"
                        :key="index"
                    >
                        <b-card v-if="isLoadingDashboardShopData">
                            <b-skeleton-img no-aspect height="250px"></b-skeleton-img>
                        </b-card>
                        <CardSimple
                            v-else
                            :cardTitle="item.name"
                            :cardDescription="item.description"
                            :availablePoints="item.points"
                            :cardCost="item.cardCost"
                            :btnLink="item.btnLink"
                            :isActive="myPointsCoin >= item.points"
                            :onButtonClick="
                                () => onButtonClickEfficiencyItemsData(item._id)
                            "
                            btnText="Приобрести"
                        />
                    </div>
                    <div
                        v-if="
                            this.$store.getters.getDashboardShop.shop.studyOptions.filter(
                                (item) => item.status === 'active'
                            ).length > 3 && !isLoadingDashboardShopData
                        "
                        :class="
                            viewAll
                                ? 'dashboardShopContent__viewAll dashboardShopContent__viewAll--fourth'
                                : 'dashboardShopContent__viewAll'
                        "
                        @click="toggleCards()"
                    >
                        <p class="h3 colorGullGray dashboardShopContent__viewAll_text">
                            {{ viewAllText }}
                        </p>
                    </div>
                </div>
                <div v-else class="dashboardShopContent__noContent">
                    <p class="h2 colorBotticelli">
                        У вас ещё нет доступных к покупке товаров по повышению
                        эффективности своего обучения.
                    </p>
                </div>

                <p class="pageSubtitle h2 colorFiord">
                    Получить скидку на одну из программ
                </p>
                <div class="dashboardShopContent">
                    <div
                        class="dashboardShopContent__allItem"
                        v-for="(item, index) in discountItems"
                        :key="index"
                    >
                        <b-card v-if="isLoadingDashboardShopData">
                            <b-skeleton-img no-aspect height="250px"></b-skeleton-img>
                        </b-card>
                        <CardSimple
                            v-else
                            :cardTitle="item.name"
                            :cardDescription="item.description"
                            :availablePoints="item.points"
                            :cardCost="item.cardCost"
                            :btnLink="item.btnLink"
                            :isActive="myPointsCoin >= item.points"
                            :isBanner="item.isBanner"
                            :onButtonClick="() => onButtonClickDiscountItems(item._id)"
                            btnText="Получить скидку"
                        />
                        <div
                            v-if="item.isBanner && !isLoadingDashboardShopData"
                            class="dashboardShopContent__chooseProgram"
                        >
                            <p class="h3">Подобрать программу</p>
                            <div class="dashboardShopContent__chooseProgramText">
                                <p class="text2 colorGullGray">
                                    Трудно определиться с тем, какая программа Вам
                                    подходит? Мы поможем подобрать для Вас оптимальную
                                </p>
                            </div>
                            <div class="dashboardShopContent__chooseProgramButton">
                                <Button
                                    extraClass="button--white"
                                    title="нужна помощь"
                                    :onClick="() => onOpenModalHelper()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HelperPopupContent
                v-if="isVisibleModalHelper"
                :onClose="() => onCloseModalHelper()"
                :onConfirm="() => chooseProgram()"
            />
            <EarningPointsPopup
                v-if="isVisibleModalEarningPoints"
                :onClose="() => onCloseModalEarningPoints()"
            />

            <Confirmation
                v-if="isVisibleConfirmationEfficiency"
                :title="`Вы действительно хотите ${confirmationData.name}`"
                :points="`${confirmationData.points}`"
                :onClose="() => onCloseConfirmation()"
                :onConfirm="() => buyCard()"
                btnText="Приобрести"
            />

            <Confirmation
                v-if="isVisibleConfirmationDiscount"
                :title="
                    `Вы действительно хотите получить скидку на ${confirmationData.name}`
                "
                :points="`${confirmationData.points}`"
                :onClose="() => onCloseConfirmation()"
                :onConfirm="() => buyProgram()"
                btnText="Получить скидку"
            />
        </div>
    </Layout>
</template>

<script>
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Layout from "@/views/layouts/Layout";
import Button from "@/views/components/Button/Button";
import Tooltip from "@/views/components/Tooltip/Tooltip";
import Confirmation from "@/views/partials/popups/Confirmation/Confirmation";

import CardSimple from "@/views/components/CardSimple/CardSimple";
import EarningPointsPopup from "@/views/pages/dashboard/dashboardShop/earningPointsPopup/earningPointsPopup";
import HelperPopupContent from "@/views/pages/dashboard/dashboardShop/helperPopupContent/helperPopupContent";

export default {
    name: "dashboardShop",
    components: {
        PageHeader,
        Layout,
        Button,
        Tooltip,
        Confirmation,
        CardSimple,
        EarningPointsPopup,
        HelperPopupContent,
    },

    async beforeMount() {
        await this.updateDashboardShop();
        await this.updateDashboardRatingStore();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateDashboardShop();
            this.updateDashboardRatingStore();
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        daysTillPointsReset() {
            return this.member.tillPointsReset || 0;
        },

        myPointsCoin() {
            return (
                (this.$store.getters.getDashboardRating &&
                    this.$store.getters.getDashboardRating.rating &&
                    this.$store.getters.getDashboardRating.rating.myPointsCoin) ||
                "0"
            );
        },

        efficiencyItemsData() {
            return this.$store.getters.getDashboardShop.shop.studyOptions.filter(
                (item) => item.status === "active"
            );
        },
    },

    data() {
        return {
            viewAll: true,
            viewAllText: `Показать еще ${this.$store.getters.getDashboardShop.shop.studyOptions.filter(
                (item) => item.status === "active"
            ).length - 3} предложений`,
            isBanner: false,
            isVisibleConfirmationEfficiency: false,
            isVisibleConfirmationDiscount: false,
            isLoadingDashboardShopData: false,
            isLoadingActivePointsData: false,

            lessonId: "",
            closedLessonId: "",
            blockId: "",
            blockIdClosed: "",

            title: "Магазин",
            items: [
                {
                    text: "Личный дашборд",
                    to: `/${this.$route.params.slug}/dashboard`,
                },
                {
                    text: "Магазин",
                    active: true,
                },
            ],

            // Удалить проверку по айтем id после фикса формы подбора программы на сервер сайде.
            discountItems: [
                ...this.$store.getters.getDashboardShop.shop.courseOptions[0].courses
                    .filter(
                        (item) =>
                            item.status === "active" &&
                            item._id !== "61716785ff5a2e84a4f9c2d4"
                    )
                    .slice(0, 3),
                {
                    isBanner: true,
                },
                ...this.$store.getters.getDashboardShop.shop.courseOptions[0].courses
                    .filter(
                        (item) =>
                            item.status === "active" &&
                            item._id !== "61716785ff5a2e84a4f9c2d4"
                    )
                    .slice(3),
            ],

            isVisibleModalEarningPoints: false,
            isVisibleModalHelper: false,
        };
    },
    methods: {
        toggleCards() {
            this.viewAll = !this.viewAll;
            this.viewAll
                ? (this.viewAllText = `Показать еще ${this.$store.getters.getDashboardShop
                      .shop.studyOptions.length - 4} предложений`)
                : (this.viewAllText = "Свернуть");
        },

        onOpenModalEarningPoints() {
            this.isVisibleModalEarningPoints = true;
        },
        onCloseModalEarningPoints() {
            this.isVisibleModalEarningPoints = false;
        },

        onOpenModalHelper() {
            this.isVisibleModalHelper = true;
        },
        onCloseModalHelper() {
            this.isVisibleModalHelper = false;
        },

        async onButtonClickEfficiencyItemsData(id) {
            this.confirmationData = this.efficiencyItemsData.find(
                (item) => item._id === id
            );
            this.isVisibleConfirmationEfficiency = true;

            await this.$store.dispatch("clearAlert");
        },

        async onButtonClickDiscountItems(id) {
            this.confirmationData = this.discountItems.find((item) => item._id === id);
            this.isVisibleConfirmationDiscount = true;

            await this.$store.dispatch("clearAlert");
        },

        onCloseConfirmation() {
            this.isVisibleConfirmationEfficiency = false;
            this.isVisibleConfirmationDiscount = false;
        },

        async updateDashboardShop() {
            this.isLoadingDashboardShopData = true;
            const getDashboardShop = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/shop"
            );

            if (
                getDashboardShop &&
                getDashboardShop.data &&
                getDashboardShop.data.result &&
                getDashboardShop.data.data
            ) {
                await this.$store.dispatch(
                    "setDashboardShop",
                    getDashboardShop.data.data
                );
            } else {
                console.error(
                    "Ошибка при поиске магазина: " + (getDashboardShop.data.message || "")
                );
                await this.$store.dispatch("clearDashboardShop");
            }
            this.isLoadingDashboardShopData = false;
        },

        async updateDashboardRatingStore() {
            this.isLoadingActivePointsData = true;
            const getDashboardRating = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/rating"
            );

            if (
                getDashboardRating &&
                getDashboardRating.data &&
                getDashboardRating.data.result
            ) {
                await this.$store.dispatch(
                    "setDashboardRating",
                    getDashboardRating.data.response
                );
            } else {
                console.error(
                    "Ошибка при поиске рейтинга: " +
                        (getDashboardRating.data.message || "")
                );
                await this.$store.dispatch("clearDashboardRating");
            }
            this.isLoadingActivePointsData = false;
        },

        chooseProgram: async function() {
            const settingId = this.$store.getters.getDashboardShop.shop
                .offerCourseDiscount._id;
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/progress/buy/course/${settingId}`
            );
            if (response && response.data && response.data.result) {
                this.onCloseModalHelper();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Заявка на подбор программы по скидке отправлена",
                    },
                });
            } else {
                this.onCloseModalHelper();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            "У Вас недостаточно балов для покупки. Для отправки запроса на скидку необходимо иметь как минимум 100 балов доступных для траты",
                    },
                });
            }
        },

        // Переделать логику работы, отправлять один запрос с добалением айтем тайпа или айди. (После фикса на сервер сайде.)

        buyCard: async function() {
            let response = "";

            switch (this.confirmationData.type) {
                case "resetProgress":
                    response = await this.axios.get(
                        `/programs/${this.$route.params.slug}/study/reset_progress/buy`
                    );
                    break;
                case "additionalMaterials":
                    response = await this.axios.get(
                        `/programs/${this.$route.params.slug}/bonuses/1/buy`
                    );
                    break;
                case "openAdditionalPart":
                    try {
                        response = await this.axios.post(`/programs/${this.$route.params.slug}/study/shop/openAdditionalLessonAndAnswers`);
                    } catch (error) {
                        response = error.response;
                    }
                    break;
                case "openAdditionalAnswer":
                    try {
                        response = await this.axios.post(`/programs/${this.$route.params.slug}/study/shop/openAdditionalAnswers`);
                    } catch (error) {
                        response = error.response;
                    }
                    break;
                case "certificate":
                    response = await this.axios.post(
                        `/programs/${this.$route.params.slug}/study/certificate/buy`
                    );
                    break;
                default:
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Ошибка при покупке бонуса.",
                        },
                    });
            }

            if (response && response.data && response.data.result) {
                this.onCloseConfirmation();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Товар успешно приобретен.",
                    },
                });
                await this.updateDashboardRatingStore();
            } else {
                this.onCloseConfirmation();

                let error = "Ошибка при покупке дополнительной части";

                if (response.data && response.data.error) {
                    error = response.data.error;
                } else if (response.data && response.data.message) {
                    error = response.data.message;
                }

                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: error,
                    },
                });
                await this.updateDashboardRatingStore();
            }
        },

        buyProgram: async function() {
            const settingId = this.confirmationData._id;
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/progress/buy/course/${settingId}`
            );
            if (response && response.data && response.data.result) {
                this.onCloseConfirmation();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "success",
                        text: "Заявка на подбор программы по скидке отправлена",
                    },
                });
                await this.updateDashboardRatingStore();
            } else {
                this.onCloseConfirmation();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            "Запрос на покупку скидки для данного курса уже отправлялся",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "dashboardShop.scss";
</style>
